import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../PageCss/HansaFlexIndiaPages/Journey.css';
import JourneyCard from '../../Components/JourneyCard/JourneyCard';
import img2004 from '../../assets/Journey/2004.png';
import img2005 from '../../assets/Journey/2005.png';
import img2005ribbon from '../../assets/Journey/2005_ribbon.png';
import img2007 from '../../assets/Journey/2007.png';
import img2019 from '../../assets/Journey/2019.png';
import img2023 from '../../assets/Journey/2023_2.jpg';

import JourneyCardR from '../../Components/JourneyCard/JourneyCardR';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgHistoryStage from '../../assets/company-history.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgUSAjourney2 from '../../assets/Journey/USAjourney2.png'

function Journey() {

    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 300,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);



    return (
        <div className='journey-content'>
            <CenterStage imageSrc={imgUSAjourney2} title="Come with us on our Journey !" paragraph="HANSA-FLEX USA boasts a storied history defined by strategic evolution and adaptability. Over time, it has showcased resilience and innovation, adeptly surmounting obstacles while steadily broadening its impact within the industry." />

            <div className='div-timeline-wrapper'>
                <br /><br /><br /><br />
                {isMobile ? <></> : <><div className='vertical-line'></div></>}

                <div>
                    <JourneyCard
                        title="2004"
                        description="Formation of HANSA-FLEX Hydraulics USA Inc."
                        imageUrl={img2004}
                        additionalDescription="Building: 295 Connie Cr, Unit 3"
                    />
                </div>

                <div >
                    <JourneyCardR
                        title="2005"
                        description="HANSA-FLEX USA's Opening Party of 2005"
                        imageUrl={img2005}
                        additionalDescription="Opening Party"
                    />
                </div>

                <div >
                    <JourneyCard
                        title="2005"
                        description="Official Ribbon cutting, HFC USA Opening"
                        imageUrl={img2005ribbon}
                        additionalDescription="Ribbon cutting"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2007"
                        description="HFC in National Heavy Equipment Show, Toronto"
                        imageUrl={img2007}
                        additionalDescription="Equipment Show"
                    />
                </div>

                <div>
                    <JourneyCard
                        title="2019"
                        description="National Heavy Equipment Show "
                        imageUrl={img2019}
                        additionalDescription="Equipment Show"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2023..."
                        description="Always assisting with hydraulic solutions, resilience, and innovation!"
                        imageUrl={img2023}
                        additionalDescription="Future of reliable hydraulics"
                    />
                </div>
            </div>
        </div>
    );
}

export default Journey;
