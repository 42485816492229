import React, { useEffect } from 'react';
import PdfViewer from '../Components/PdfViewer/PdfViewer';
import pdfFilePath from '../assets/Disclaimer.pdf'; // Import the PDF file

function Disclaimer() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <PdfViewer filePath={pdfFilePath} />
        </div>
    );
}

export default Disclaimer;
