import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';
import imgTUV1 from '../../assets/Certificates/TUV Certificate -ISO14001 1.jpg';
import imgTUV2 from '../../assets/Certificates/TUV Certificate -ISO45001 1.jpg';
import imgISO9001 from '../../assets/Certificates/ISO-9001-2015-New Certificate-2022-2025 1.jpg';
import imgCertificationISO90012015_01 from '../../assets/Certificates/CertificationISO 90012015_01.jpg';
import imgCertificationISO90012015_02 from '../../assets/Certificates/CertificationIS0 140012015_02.jpg';
import imgCertificationISO450012018 from '../../assets/Certificates/CertificationISO450012018.jpg';


function Certificates() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CertificateCard title={"Certification ISO 9001:2015"} description={"Accreditation for ISO 9001:2015 standards, affirming commitment to quality management systems, enhancing organizational efficiency, customer satisfaction and global market competitiveness."} imageUrl={imgCertificationISO90012015_01} />
            <CertificateCard title={"Certification IS0 14001:2015"} description={"ISO 9001:2015 is an international standard for Quality Management Systems (QMS). It outlines the criteria for a quality management system and is based on several quality management principles including a strong customer focus, the involvement of top management, the process approach, and continual improvement."} imageUrl={imgCertificationISO90012015_02} />
            <CertificateCard title={"Certification ISO 45001:2018"} description={"SO 45001:2018 certification for Occupational Health and Safety in the Mobile Services sector, including Industrial and Rapid Hydraulic Services. This certification underscores our commitment to minimizing workplace hazards and reducing accidents. Our goal is for every employee to return home safely each day, and we all share responsibility for maintaining a safe work environment."} imageUrl={imgCertificationISO450012018} /> */}

        </div>
    );
}

export default Certificates;