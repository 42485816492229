import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import ImageText from '../../Components/ImageText/ImageText';
import imgStage from '../../assets/WhoWeAre/stage.jpg';
import { useMediaQuery } from 'react-responsive';
import Promoter from '../../Components/Promoter/Promoter';

import imgCounter from '../../assets/WhoWeAre/counter.jpg';
import imgStock from '../../assets/WhoWeAre/stock.jpg';
import imgTeams from '../../assets/WhoWeAre/teamworkr.png';
import imgService from '../../assets/WhoWeAre/service.jpg';
import hose from '../../assets/HoseLogo.png';
import imgCartons from '../../assets/Hansa-Flex_Kartons_Shop.png';
import imgLeadHydraulics from "../../assets/lead-hydraulics.jpg";
import imgVan from '../../assets/brandImages/Services_MHS_Kanalseite_01.jpg';
import imgLogistics from '../../assets/LogisticsImg.jpg';


import imgCustomerSupport from '../../assets/customer-support.png';
import imgPromotion from '../../assets/promotion.png';
import imgRelationship from '../../assets/relationship.png';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import { FaPhoneVolume } from 'react-icons/fa';
import { MdLocationOn, MdOutlineEmail } from 'react-icons/md';
import { Button, Col, Row } from 'react-bootstrap';
import FullImageText from '../../Components/FullImageText/FullImageText';



function WhoWeAre() {
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size



    const dataBrazing = {
        image: imgCounter,
        title: 'Discover Our Retail Service Counter!',
        description: "Step into our facility and immerse yourself in our bespoke Retail Service Counter, meticulously tailored to cater to each customer's unique hose and tube assembly needs with unrivalled speed and precision."
    }

    const dataStock = {
        image: imgStock,
        title: 'Extensive Stock Availability:',
        description: "With a vast inventory boasting over 13,000 parts, rest assured that we offer prompt solutions for even the most specialized requests."
    }

    const promoterTitle = "We have a Competent Team:";
    const promoterChecklistItems = ["Our team, with over three decades of hydraulic expertise, forms the backbone of our operation. Fuelled by a collective passion for excellence, we are dedicated to delivering unparalleled quality, swift service, and an unyielding commitment to innovation."];


    // features card carousel data
    const featuresData = [
        {
            title: "Customer Support",
            description: "Offering dedicated assistance and guidance to our clients in USA.",
            image: imgCustomerSupport
        },
        {
            title: "Extensive Stock Availability",
            description: "We have stocks of products around 13000 units!",
            image: imgCartons
        },
        {
            title: "Premium Quality Hoses",
            description: "Experience our unmached Quality",
            image: hose
        }
        // Add more objects for additional FeaturesCard data
    ];

    const carouselItems = [
        {
            imgSrc: imgService,
            title: 'Easy-Order Easy-Delivery',
            description: 'At HANSA-FLEX USA we provide best of customer service and take care that the quality product is delivered.'
        },

        {
            imgSrc: imgLogistics,
            title: 'We Make it, We deliver it ',
            description: 'HANSA‑FLEX USA ensures hoses and fittings are consistently stocked, guaranteeing uninterrupted operations for clients. With a proactive approach, we prioritize maintaining ample inventory, delivering reliability and efficiency to meet diverse hydraulic system needs.'
        }
    ];


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage imageSrc={imgStage} title={"Welcome to HANSA-FLEX Hydraulics USA"} paragraph={"HANSA-FLEX USA is a proud member of the esteemed HANSA-FLEX Group, a global leader in hydraulic systems and solutions. Established to bring the same high standards of quality, innovation, and service that define the HANSA-FLEX brand worldwide, HANSA-FLEX USA offers a comprehensive range of hydraulic components and services tailored to meet the needs of industries across North America. With a commitment to excellence, HANSA-FLEX USA ensures that customers benefit from the global expertise and resources of the HANSA-FLEX network, delivering reliable and efficient hydraulic solutions that drive productivity and growth."} />


            <FullImageText
                imageSrc={imgVan}
                altText="Stage"
                title="Fast, flexible and personal"
                paragraph="HANSA‑FLEX blends owner-managed agility with international professionalism, ensuring high-quality fluid technology solutions worldwide. Cooperation, respect, and teamwork define our interactions, enabling us to keep customers' machines operational across borders."
                imageWidth={1}
            />

            <div>
                {/* <ImageText data={dataBrazing} /> <br /><br /> */}
                {/* <ImageText data={dataStock} left={false} /> <br /><br /> */}


            </div>

            {/* <div>
                {isLaptop && (
                    <Promoter
                        title={promoterTitle}
                        checklistItems={promoterChecklistItems}
                        btnLabel1={"Find Out More !"}
                        imageUrl={imgTeams}
                    />
                )}
            </div> */}


            <br /><br /><br />


            <div className=' div-gray'>
                <SectionHeader title={"Why choose us ?"} paragraph={"We are the epitome of excellence, renowned for our unwavering commitment to quality and customer satisfaction. Ww deliver unparalleled solutions tailored to your needs."} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>


            <div>

                <br /><br /><br /><br />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={1} />
            </div>

            <br /><br /><br />
            <br /><br /><br />

            <div className="text-center div-gray">
                <SectionHeader
                    title={"Explore about HANSA-FLEX Group"}
                    paragraph={"HANSA‑FLEX is a global family-run company and one of the world’s leading system partners for fluid technology. In the last business year, more than 5,000 employees in 40 countries achieved a turnover of EUR 570 million."} />
                <br />
                <Button variant="danger" href={"https://www.hansa-flex.de/en/company/"} target="_blank" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>Learn more about HANSA-FLEX</Button>

                <br /><br /><br />
            </div>


            <div>

                <br /><br /><br /><br />

                <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                    <Row>
                        <Col xs={12} md={6}>
                            <h2>Contact Details:</h2>
                            <p>For inquiries, assistance, or collaboration opportunities, please reach out:</p>
                            <p> <MdLocationOn /> Address: <a href="https://maps.app.goo.gl/F5TxKgowgsSHnFkA7" style={{ textDecoration: 'none', color: 'white' }} > 10821 Train Ct, Houston, TX 77041</a></p>
                            <p> <MdOutlineEmail /> Email: <a href="mailto:cac@hansa-flex.com" style={{ textDecoration: 'none', color: 'white' }}>     USTXHSALES@hansa-flex.com</a></p>
                            <p> <FaPhoneVolume /> Phone: <a href="tel:+19057602224" style={{ textDecoration: 'none', color: 'white' }} >     (+1) 713 466 6673</a></p>
                        </Col>
                    </Row>
                </div>


            </div>






        </div>
    );
}

export default WhoWeAre;