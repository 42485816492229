import React from 'react';
import PropTypes from 'prop-types';
import './PDFViewer.css'

const PDFViewer = ({ filePath }) => {

    const pdfWithSettings = `${filePath}#zoom=200&toolbar=0&navpanes=0`;

    return (
        <div className="pdf-preview-container">
            <iframe
                src={pdfWithSettings}
                title="PDF Preview"
                className="pdf-preview"
                frameBorder="0"
            ></iframe>
        </div>
    );
};

PDFViewer.propTypes = {
    filePath: PropTypes.string.isRequired,
};

export default PDFViewer;
