import React, { useEffect } from 'react';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgHose from '../../assets/hose.jpg';
import imgManagementStage from '../../assets/management/Management_image_IFP.jpg';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';
import imgMarianne from '../../assets/management/DSC_6123.jpg';
import imgAneel from '../../assets/aneelCraciun.png';


function Management() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage title={"Meet our Management"} imageSrc={imgHose} paragraph={"At HANSA-FLEX, our management is influential in guiding the company with strategic vision and operational excellence. Through dynamic leadership and a focus on innovation, we prioritize customer satisfaction and sustainable growth. Our management fosters a culture of collaboration and empowerment, driving success across all facets of the organization."} />

            <div>
                <ContactBubble
                    imageSrc={imgAneel}
                    title="Aneel Craciun"
                    subTitle="Chief Executive Officer"
                    description="Aneel Craciun, our visionary Chief Executive Officer, leads with strategic insight, driving growth and innovation. With extensive experience, he cultivates excellence throughout our organization, ensuring both success and customer satisfaction."
                />
            </div>
        </div>
    );
}

export default Management;